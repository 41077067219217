import React from "react";
import Router from "next/router";
import ReactMarkdown from "react-markdown";
import Image from "next/image";
import { AppProps } from "next/app";
import { DocumentTextIcon } from "@heroicons/react/solid";
import { HomeIcon } from "@heroicons/react/solid";
import { BeakerIcon } from "@heroicons/react/solid";

export type CountryProps = {
  //   id: string;
  name: string;
  //   author: {
  //     name: string;
  //     email: string;
  //   } | null;
  //   content: string;
  //   published: boolean;
};

const CountryCard: React.FC<{ country: any }> = ({ country }) => {
  console.log({ country });

  if (true) {
    return (
      <>
        <div className="grid grid-cols-1 justify-center relative overflow-hidden sm:py-12">
          <div className="max-w-xl mx-auto sm:py-1 py-4 w-full">
            <div className="relative group">
              <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
              <div className="relative px-7 py-6 bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6">
                <div className="space-y-2">
                  <h1>
                    <strong>{country?.data?.area?.name}</strong>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-xl mx-auto sm:py-1 py-4 w-full">
            <div className="relative group">
              <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
              <div className="relative px-7 py-6 bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6">
                <DocumentTextIcon className="h-5 w-5 text-purple-600" />

                <div className="space-y-2">
                  <h1>
                    <strong>Travel Documents</strong>
                  </h1>
                  <div
                    className="text-slate-800"
                    dangerouslySetInnerHTML={{
                      __html:
                        country?.data?.areaAccessRestriction
                          ?.declarationDocuments?.text,
                    }}
                  ></div>
                  <a
                    href={
                      country?.data.areaAccessRestriction.declarationDocuments
                        .travelDocumentationLink
                    }
                    className="block text-indigo-400 group-hover:text-slate-800 transition duration-200"
                    target="_blank"
                  >
                    Source →
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-xl mx-auto sm:py-1 py-4 w-full">
            <div className="relative group">
              <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
              <div className="relative px-7 py-6 bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6">
                <HomeIcon className="h-5 w-5 text-purple-600" />
                <div className="space-y-2">
                  <h1>
                    <strong>Entry Requirements</strong>
                  </h1>
                  <div
                    className="text-slate-800"
                    dangerouslySetInnerHTML={{
                      __html: country?.data?.areaAccessRestriction?.entry?.text,
                    }}
                  ></div>
                  <a
                    href={
                      country?.data.areaAccessRestriction.entry.referenceLink
                    }
                    className="block text-indigo-400 group-hover:text-slate-800 transition duration-200"
                    target="_blank"
                  >
                    Source →
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-xl mx-auto sm:py-1 py-4 w-full">
            <div className="relative group">
              <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
              <div className="relative px-7 py-6 bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6">
                <BeakerIcon className="h-5 w-5 text-purple-600" />

                <div className="space-y-2">
                  <h1>
                    <strong>Testing Requirements</strong>
                  </h1>
                  <div
                    className="text-slate-800"
                    dangerouslySetInnerHTML={{
                      __html:
                        country?.data?.areaAccessRestriction?.travelTest
                          ?.isRequired,
                    }}
                  ></div>
                  {/* <a
                    href={
                      country?.data?.areaAccessRestriction?.diseaseTesting
                        ?.rules
                    }
                    className="block text-indigo-400 group-hover:text-slate-800 transition duration-200"
                    target="_blank"
                  >
                    Source →
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default CountryCard;
