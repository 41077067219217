import React from "react";
import CountryCard from "@components/CountryCard";

import restrictionSearch from "@utils/search/restrictionSearch";
import Head from "next/head";

var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const Country = (props: any) => {
  return (
    <>
      <Head>
        <title>
          DocuTravel - {props?.restrictionData?.data?.area?.name} Travel
          Restrictions
        </title>
        <meta
          name="description"
          content="Up to date travel restrictions and documents for YourNextGetaway"
        />
        {/* <meta name="keywords" content="travel, "/> */}
        <meta name="author" content="Megan Parker - Luxury Travel Agent" />
      </Head>
      <div className="page">
        <br></br>
        <main>
          <CountryCard country={props.restrictionData} />
        </main>
      </div>
      <style jsx>{`
        .post {
          background: white;
          transition: box-shadow 0.1s ease-in;
        }

        .post:hover {
          box-shadow: 1px 1px 3px #aaa;
        }

        .post + .post {
          margin-top: 2rem;
        }
      `}</style>
    </>
  );
};

export async function getStaticProps(context: any) {
  // If this request throws an uncaught error, Next.js will
  // not invalidate the currently shown page and
  // retry getStaticProps on the next request.
  // const res = await fetch('https://.../posts')
  // const posts = await res.json()

  // if (!res.ok) {
  //   // If there is a server error, you might want to
  //   // throw an error instead of returning so that the cache is not updated
  //   // until the next successful request.
  //   throw new Error(`Failed to fetch posts, received status ${res.status}`)
  // }

  // If the request was successful, return the posts
  // and revalidate every 10 seconds.

  console.log(context?.params.country);

  // validate that country is a valid country code
  // if valid country code, do below (reference the array of country codes)
  try {
    const restrictionData = await restrictionSearch(context?.params?.country);

    return {
      props: {
        // posts,
        restrictionData,
      },
      revalidate: 86400,
    };
  } catch (e) {
    console.error(e);
    return {
      props: {
        restrictionData: null,
      },
    };
  }
}

export async function getStaticPaths() {
  return {
    paths: [
      // String variant:
      //   "/FR",
      //   "/US",
      // Object variant:
    ],
    fallback: "blocking",
  };
}

export default Country;
